@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-green {
    @apply rounded-lg bg-[#0be898] px-4 py-2 font-bold text-black hover:bg-[#06d388];
  }

  .btn-back {
    @apply rounded-lg bg-[#f75c77] px-4 py-2 font-bold text-black hover:bg-[#d34d65];
  }

  .btn-circle {
    @apply relative h-[200px] w-[200px] border-spacing-4 overflow-hidden rounded-full border-2 border-[#06d388] text-2xl font-bold text-white hover:border-[#06d388] hover:bg-gradient-to-t hover:from-[#06d388]/50 hover:to-[#06d388]/40 disabled:cursor-not-allowed disabled:border-[#d84640] disabled:bg-gradient-to-t disabled:from-[#d84640]/50 disabled:to-[#d84640]/40 disabled:text-[#f87171];
  }

  .bg-glass {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(4.4px);
    -webkit-backdrop-filter: blur(4.4px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gilroy Light";
  src: url("../public/fonts/gilroy-light.woff2") format("woff2");
}

.font-gilroy-light {
  font-family: "Gilroy Light", sans-serif;
}

.appear-flash {
  animation-name: blinker;
  animation-iteration-count: var(1);
  animation-duration: 3s;
}

@keyframes blinker {
  from {
    background-color: #c80f77;
  }

  to {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

/* Add these styles to override the Solana wallet button */
.wallet-adapter-button-trigger {
  background-color: transparent !important;
  padding: 0.5rem !important;
  height: 2.5rem !important;
  color: rgb(229 231 235) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  width: 100% !important;
  justify-content: flex-start !important;
  border-radius: 0.5rem !important;
  line-height: 1.5rem !important;
}

.wallet-adapter-button-trigger:hover {
  background-color: rgb(75 85 99 / 0.5) !important;
}

.wallet-adapter-button-trigger:not([disabled]):hover {
  background-color: rgb(75 85 99 / 0.5) !important;
}


@keyframes swipeHint {
  0%, 100% { transform: translateX(0); }
  50% { transform: translateX(10px); }
}

.swipe-hint {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  pointer-events: none;
  animation: swipeHint 1.5s infinite;
  z-index: 50;
}

#page-content {
  transition: transform 300ms ease-in-out;
  width: 100%;
  transform: translateX(0);
}

/* Exit animations */
.page-transition-left-exit {
  transform: translateX(-100%) !important;
}

.page-transition-right-exit {
  transform: translateX(100%) !important;
}

/* Enter animations */
.page-transition-left-enter {
  transform: translateX(100%) !important;
}

.page-transition-right-enter {
  transform: translateX(-100%) !important;
}